<template>
	<div id="miniGoodsEdit">	
		<page-goods-edit :is-shop="0"></page-goods-edit>
	</div>

</template>

<script>
	import pageGoodsEdit from '@/components/layout/goods/page-goods-edit.vue'
	export default {
		components: {
			pageGoodsEdit
		},
		data() {
			return {
			
			}
		},
		mounted() {
			
		},
		beforeRouteLeave (to, from, next) { 
			to.meta.keepAlive = to.path == '/goods/list' ? true :false
			console.log(to.meta.keepAlive);
			
			next();
		},
		methods: {
			
		}
	}
</script>

<style>
	
</style>
